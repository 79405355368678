import React from 'react';
import { graphql } from 'gatsby';
import ArticlesList from 'components/ArticlesList';

const BlogCategory = ({ data, pageContext }) => (
  <ArticlesList data={data} pageContext={pageContext} />
);

export const query = graphql`
  query($slug: String!) {
    datoCmsBlogPage {
      title
      description
    }

    allDatoCmsTag {
      edges {
        node {
          id
          slug
          name
        }
      }
    }

    allDatoCmsArticle(
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          slug
          title
          kicker
          meta {
            firstPublishedAt
          }
          cover {
            url
            fluid(maxHeight: 200) {
              ...GatsbyDatoCmsFluid
            }
          }
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export default BlogCategory;
